// extracted by mini-css-extract-plugin
export var bankId = "bankIdLoader-module--bankId--ecc34";
export var container = "bankIdLoader-module--container--e372a";
export var dot = "bankIdLoader-module--dot--31e3d";
export var dot_1 = "bankIdLoader-module--dot_1--09bc0";
export var dot_2 = "bankIdLoader-module--dot_2--57383";
export var dots = "bankIdLoader-module--dots--2cf84";
export var innerWrapper = "bankIdLoader-module--innerWrapper--eacb1";
export var link = "bankIdLoader-module--link--61fb4";
export var loader = "bankIdLoader-module--loader--8266f";
export var verification_text = "bankIdLoader-module--verification_text--ab949";