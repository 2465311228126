import React, { useEffect, useState, useRef } from 'react';
import * as styles from './bankIdLoader.module.css';
import BankidIcon from '@icons/bank_id.svg';
import PrimaryButton from './primaryButton';
import { Helmet } from 'react-helmet';

const isMobile =
  typeof navigator !== 'undefined' && navigator.userAgent.match(/mobi/i);
function BankIdLoader() {
  const bankIdLink = useRef(null);
  const [hasNavigated, setHasNavigated] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (bankIdLink.current && !hasNavigated) {
      bankIdLink.current.click();
      setHasNavigated(true);
    }
  }, []);

  return (
    <div className={`container ${styles.container}`}>
      <Helmet>
        <body />
      </Helmet>
      <div className={styles.innerWrapper}>
        <div className={styles.bankId}>
          <BankidIcon />
        </div>
        <div className={styles.dots}>
          <span className={styles.dot} />
          <span className={styles.dot_1} />
          <span className={styles.dot_2} />
        </div>
        <p className={`text-small ${styles.verification_text} `}>
          Starta Mobilt BankID på din mobiltelefon för att verifiera din
          identitet.
        </p>
        {isMobile && (
          <PrimaryButton>
            <a
              href="bankid:///?redirect="
              className={styles.link}
              ref={bankIdLink}
            >
              Öppna BankID
            </a>
          </PrimaryButton>
        )}
      </div>
    </div>
  );
}

export default React.memo(BankIdLoader);
