import React from 'react';
import { graphql } from 'gatsby';
import {
  OrdnaQuery as PricingQuery,
  ContentfulCard,
  ContentfulDialogContent,
} from '../../graphql-types';

import SEO from '@components/seo';
import Layout from '@components/Layout/Layout';
import Container from '@components/Container';

import useContentfulImage from '@src/hooks/useContentfulImage.hook';
import * as styles from './prissattning.module.scss';
import ProductHero from '@src/components/ProductHero';
import Footer from '@src/components/Footer/Footer';
import PrimaryButton from '@components/PrimaryButton/PrimaryButton';
import UspList, { UspListItem } from '@src/components/UspList/UspList';
import { ContentfulTextList } from '../../../graphql-types';
import RockerPayPricingTable from '@src/components/RockerPayPricingTable';

type PricingProps = {
  data: PricingQuery;
};

function Pricing({ data }: PricingProps) {
  const {
    contentfulPage: {
      headLine,
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
      pageHeaderSubtitle: { pageHeaderSubtitle },
      heroImage,
      pageContent,
    },
  } = data;

  const heroImg = useContentfulImage(heroImage);

  const uspList = (pageContent[0] as ContentfulTextList).advancedTextListItems;

  const navLinks = [
    {
      name: 'Rocker Pay',
      href: '/pay/foretag',
    },
    {
      name: 'Prissättning',
      href: '/pay/prissattning',
    },
  ];

  return (
    <Layout theme="white" subNavLinks={navLinks} customFooter={<></>}>
      <SEO title={headLine} />
      <Container>
        <ProductHero
          title={pageHeader}
          subtitle={pageHeaderSubtitle}
          image={heroImg}
        >
          <UspList items={uspList as UspListItem[]} />
          <PrimaryButton
            color="purple"
            href="https://airtable.com/appSfUxboHmAtHoqE/shrTq9fLMpK8D3yQY"
          >
            Få en offert
          </PrimaryButton>
        </ProductHero>
        <div className={styles.tableContainer}>
          <div className={styles.pricingHeader}>Allt som ingår</div>
          <RockerPayPricingTable />
        </div>
      </Container>
      <div className={styles.bottomContainer}>
        <Footer />
      </div>
    </Layout>
  );
}

export default Pricing;

export const query = graphql`
  query Pricing($locale: String) {
    contentfulPage(
      pageUrl: { eq: "/pay/prissattning" }
      node_locale: { eq: $locale }
    ) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      pageHeaderSubtitle {
        pageHeaderSubtitle
      }
      heroImage {
        title
        gatsbyImageData(width: 1040)
      }
      pageContent {
        ... on ContentfulCard {
          id
          title
          subtitle
          backgroundImage {
            gatsbyImageData(width: 1040)
            title
          }
          linkText
          linkPath
        }
        ... on ContentfulTextList {
          listName
          advancedTextListItems {
            icon
            text
          }
        }
      }
    }
  }
`;
