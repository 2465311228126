// extracted by mini-css-extract-plugin
export var bold = "RockerPayPricingTable-module--bold--3fb4e";
export var fontBold = "RockerPayPricingTable-module--fontBold--18940";
export var label = "RockerPayPricingTable-module--label--691a6";
export var listExamples = "RockerPayPricingTable-module--listExamples--fe64c";
export var row = "RockerPayPricingTable-module--row--30295";
export var rowTitle = "RockerPayPricingTable-module--rowTitle--9bd68";
export var smallSize = "RockerPayPricingTable-module--smallSize--a2dc1";
export var subheader = "RockerPayPricingTable-module--subheader--4ae89";
export var table = "RockerPayPricingTable-module--table--3954e";
export var tableWrapper = "RockerPayPricingTable-module--tableWrapper--7147e";
export var titleRow = "RockerPayPricingTable-module--titleRow--71fd2";
export var value = "RockerPayPricingTable-module--value--8d963";