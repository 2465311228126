import React from 'react';
import classNames from 'classnames';
import Container from '@components/Container';
import * as styles from './RockerPayPricingTable.module.scss';
import data1 from './data.json';

function RockerPayPricingTable() {
  const renderRow = (row, i) => {
    return (
      <div className={classNames([styles.row])} key={row.title}>
        <div
          className={classNames({
            [styles.rowTitle]: true,
          })}
        >
          {row.title}
        </div>
        <div
          className={classNames({
            [styles.value]: true,
          })}
        >
          {row.value}
        </div>
      </div>
    );
  };

  return (
    <Container className={styles.tableWrapper}>
      <div className={styles.table}>{data1.data.map(renderRow)}</div>
    </Container>
  );
}

export default RockerPayPricingTable;
